<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormInstallmentPlan
		v-else
		:is-submitting="installment.create.isCreating"
		:group-id="$route.params.id"
		:method-id="$route.params.methodId"
		:channel-id="$route.params.channelId"
		:gateway="paymentMethodDetails.gateway"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormInstallmentPlan from '@/components/FormInstallmentPlan.vue';

export default {
	name: 'InstallmentPlanChannelCreate',

	components: {
		FormInstallmentPlan,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('payments', ['installment']),

		...mapGetters({
			paymentMethodDetails: 'payments/paymentMethodDetails',
		}),
	},
	async created() {
		this.isLoading = true;
		await this.getPaymentMethod(this.$route.params.methodId);
		this.isLoading = false;

		this.setPageTitle({
			title: `Create ${this.paymentMethodDetails.name} installment plan`,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			createPlan: 'payments/createInstallmentPlanChannel',
			getPaymentMethod: 'payments/getPaymentMethod',
			setPageTitle: 'pageTitle/setPageTitle',
		}),

		async handleSubmit(params) {
			const methodId = this.$route.params.methodId;
			const groupId = this.$route.params.id;
			const channelId = this.$route.params.channelId;

			await this.createPlan({
				methodId,
				channelId,
				params,
			});

			this.$router.push({
				name: 'InstallmentPlanChannel',
				params: { id: groupId, methodId, channelId },
			});
		},
	},
};
</script>
